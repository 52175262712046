<template>
  <div class="bg-hp-gray" style="min-height: 100vh;">
    <header class="w-full">
      <Menu class="bg-hp-gray" />
    </header>


    <!-- Main content -->
    <div class="pt-36 pb-40 max-w-5xl lg:max-w-6xl 2xl:max-w-8xl mx-auto px-4" style="min-height: 100vh;">
      <div class="text-center text-blue-900 font-bold text-4xl mb-16">
        Alex's Story
      </div>

      <div class="py-12 flex flex-wrap gap-y-4 gap-x-12 justify-between text-blue-900">
        <div>
          <div class="font-bold text-lg">Location</div>
          <div class="text-lg">Missouri</div>
        </div>

        <div>
          <div class="font-bold text-lg">Situation</div>
          <div class="text-lg">1st time homeowner</div>
        </div>

        <div>
          <div class="font-bold text-lg">Job</div>
          <div class="text-lg">Roof repair & new roof</div>
        </div>

        <div>
          <div class="font-bold text-lg">Saved with Homeplan</div>
          <div class="text-lg">$1,300</div>
        </div>

        <div>
          <div class="font-bold text-lg">Status</div>
          <div class="text-lg">Before and After</div>
        </div>
      </div>

      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 pt-4 pb-8 gap-4">
        <div class="md:col-span-2 md:row-span-2 relative">
          <img class="w-full absolute z-0 rounded h-full object-cover" src="@/assets/case-studies/a/before/alex 25.jpg" alt="">
          <div style="border-color: #FCFAFD;" class="absolute -top-1.5 -left-1.5 z-100 py-2 px-6 bg-blue-200 text-blue-900 rounded-tl rounded-tr rounded-br font-semibold border-t-6 border-l-6 border-r-8 border-b-8">Before</div>
        </div>
        
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 22.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 15.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 20.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 21.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 12.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 16.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 13.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/before/alex 14.jpg" alt="">
      </div>

      <div class="grid grid-cols-3 md:grid-cols-6 grid-rows-2 py-4 gap-4">
        <div class="md:col-span-2 md:row-span-2 relative">
          <img class="w-full absolute z-0 rounded h-full object-cover" src="@/assets/case-studies/a/after/alex 2.jpg" alt="">
          <div style="border-color: #FCFAFD;" class="absolute -top-1.5 -left-1.5 z-100 py-2 px-6 bg-blue-200 text-blue-900 rounded-tl rounded-tr rounded-br font-semibold border-t-6 border-l-6 border-r-8 border-b-8">After</div>
        </div>
        
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/after/alex 8.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/after/alex 9.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/after/alex 1.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/after/alex 4.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/after/alex 7.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/after/alex 6.jpg" alt="">
        <img class="w-full rounded h-36 sm:h-48 object-cover" src="@/assets/case-studies/a/after/alex 5.jpg" alt="">
      </div>

      <div class="mt-12 mb-40 text-lg text-blue-900">
        <span class="text-4xl font-bold text-blue-900 mr-2">After</span>Bash helped Alex become a 1st time homeowner after being turned down for a traditional mortgage, purchasing her home via the St. Louis City Land Bank Program for a few thousand dollars, he got to work.
      
        <br /><br />

        Alex tossed Bash the keys, making him her home manager. He began by ordering dumpsters for the house clean out, mold removal, ripping off the roof, replacing soffit and fascia and repairing the decking himself. 
        He then hired a trusted roofing contractor to help finish the install of the new roof when he needed to focus on other areas of the home.

        <br /><br />

        By doing most of the roof work himself he was able to save Alex $1,300, which is significant savings as her home needs a top to bottom renovation and her budget is smaller than average.

      </div>
    </div>


    
    <!-- Footer -->
    <Footer />
  </div>
</template>

<script>
import Menu from "@/components/menu";
import Footer from "@/components/large-footer.vue";

export default {
  components: {
    Menu,
    Footer
  },
  methods: {
    goTo(link) {
      window.scrollTo(0, 0);
      this.$router.push(link);
    }
  }
}
</script>


<style scoped>
header {
  widows: 100%;
  position: fixed;
  z-index: 1000;
}
</style>